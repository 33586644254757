<template>
  <div class="warp">
    <!-- 新增商品 -->
    <div class="header">
      <div class="">
        <div class="title">发布服务项目须知</div>
        <router-link  :to="{ name: 'userAgreement', query: { id: 26 } }" target="_blank" style="color: #6495ff">
          《万响用户协议》
        </router-link>
      </div>
      <div>
        <ch-button class="button" type="cancel" @click="$refs.commodityWatch.openAddWatch(watchObj)">预览</ch-button>
        <ch-button class="button" type="cancel" @click="submitForm" :loading="butLoading" >上架</ch-button>
<!--        <ch-button class="button" type="ok" @click="saveDraft" :loading="saveLoading">保存为草稿</ch-button>-->
      </div>
    </div>
    <div class="information">
      <div class="title">基本信息</div>
      <ch-form :render-option="infoOption" :model="infoModalForm"
               :splice="[15]" ref="infoForm" :rules="infoRules"
               :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        <template v-slot:upload>
          <div style="display: flex;">
            <ch-upload-img
                action="/hm/general/upload"
                :img-length="1"
                :removeResponse="removeUpload"
                :uploadResponse="handleUpload"
                :fileList.sync="uploadFileList"
            >
              <div slot="tip">尺寸为750x750px及以上正方形，不能出现家政公司自身logo、<br />水印、电话、微信等联系方式、要求为纯白色底图</div>
            </ch-upload-img>
            <div class="imgTemplateList">
              <div class="imgTemplateList_title">头图模板：</div>
              <div class="imgTemplate_warp">
                <div :class="['imgTemplate_item',{'imgTemplate_item_active': imgTemplateId===item.id}]"
                     v-for="(item,i) in imgTemplateList" :key="i" @click="handleImgTemplate(item)">
                  <img style="width: 78px;" :src="item.imgUrl" alt="">
                  <div class="imgTemplate_active_icon" v-if="imgTemplateId===item.id">
                    <i class="el-icon-check" style="color: #FFFFFF"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #optionCascade>
          <el-cascader
              v-model="infoModalForm.classifyIds"
              :options="treeList"
              clearable
              :props="{value:'id',label:'name'}"
              size="small"
              @change="handleChangeClassify"
              filterable
          ></el-cascader>
        </template>
        <template #tags>
          <div class="tagsList" >
            <div class="select_warp">
              <el-select
                  v-model="selectTags"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  size="small"
                  :multiple-limit="3"
                  @change="tagChange"
                  style="flex: 1"
                  placeholder="最多选择3个">
                <el-option
                    v-for="(item,i) in tagsList"
                    :key="i"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
              <ch-button class="button" @click="openServiceTags" type="ok">服务标签库</ch-button>
            </div>
            <div class="tip">
              可以输入添加标签,标签长度不能超过6个字符
            </div>
          </div>
        </template>
        <template v-slot:city>
          <el-cascader
              ref="city"
              v-model="infoModalForm.city"
              :options="options"
              :props="{ multiple: true}"
              clearable
              collapse-tags
              style="width: 260px"
              size="small"
              @change="handleChange">
          </el-cascader>
        </template>
        <template #unDate>
          <div class="unDate">
            <el-checkbox-group v-model="unavailableTimeType" >
              <el-checkbox :disabled="unavailableTimeType.includes(2)" :label="1">工作日(周一至周五)</el-checkbox>
              <el-checkbox :disabled="unavailableTimeType.includes(1)" :label="2">周末</el-checkbox>
              <el-checkbox  :label="3">自定义</el-checkbox>
            </el-checkbox-group>
            <div style="display: flex" v-show="unavailableTimeType.includes(3)">
              <el-date-picker
                  type="dates"
                  v-model="dates"
                  @change="onChangeDate"
                  placeholder="选择一个或多个日期">
              </el-date-picker>
              <el-dropdown>
                <span class="el-dropdown-link" style="margin-left: 20px;">
                  查看<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,i) in dateSelect" :key="i">{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
        <template #payType>
          <div class="payType">
            <el-checkbox-group v-model="payType">
              <el-checkbox  label="3">先用后结</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template #activityIds>
          <div v-if="activityList && activityList.length>0">
            <el-checkbox-group v-model="activityIds" @change="handleActivityChange" :max="2">
              <el-checkbox v-for="(item,i) in activityList" :key="i" :label="item.id">
                {{item.name}}
                <el-tooltip v-if="activityList[i].description" class="item" effect="dark" placement="right">
                  <div style="white-space: pre-wrap;max-width: 300px;" v-html="activityList[i].description.replace(/\n/g, '<br/>')" slot="content"></div>
                  <i class="el-icon-info" style="color: #BFBFBF"></i>
                </el-tooltip>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
      </ch-form>
    </div>
    <div v-if="activityIds && activityIds.length>0">
      <div class="infoActivity" v-if="activityModalFormOne.name">
        <div class="title">平台活动</div>
        <ch-form :render-option="activityOptionOne" :model="activityModalFormOne"
                  :splice="[10]" ref="activityFormOne" :rules="activityRulesOne"
                 :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        </ch-form>
      </div>
      <div class="infoActivity" v-if="activityModalFormTwo.name">
        <div class="title">平台活动</div>
        <ch-form :render-option="activityOptionTwo" :model="activityModalFormTwo"
                 :splice="[10]" ref="activityFormTwo" :rules="activityRulesTwo"
                 :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        </ch-form>
      </div>
    </div>
    <div class="specification" v-if="templateId===1&&specifyModalForm.specId">
      <templateSpec ref="templateSpec" :template-id="templateId" :modal-data="specifyModalForm" :curActivityObj="curActivityObj" :table-data="tableData" :night-service="night" />
    </div>
    <div class="specification" v-if="templateId===2&&specifyModalForm.specId">
      <templateSpec ref="templateSpec" :template-id="templateId" :modal-data="serviceContent" :curActivityObj="curActivityObj" :night-service="night" />
    </div>
    <div class="shop">
      <div class="title">商品信息</div>
      <ch-form :render-option="shopOption" :model="shopModalForm"
               :splice="[10]" ref="shopForm" :rules="shopRules"
               :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        <template v-slot:shopMainImage>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="5"
              :removeResponse="removeShopMain"
              :uploadResponse="handleShopMain"
              :fileList.sync="shopMainFileList"
              style="margin-bottom: 10px;"
          >
            <div slot="tip">可上传不超过5张图片<br>尺寸为750x750px及以上正方形，不能出现家政公司自身logo、水印、电话、微信等联系方式、要求为纯白色底图</div>
          </ch-upload-img>
        </template>
        <template v-slot:shopDescript>
          <Toolbar
              style="display:flex;justify-content: center;border: 1px solid #ccc;"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
          />
          <Editor
              class="editor"
              v-loading="editorUploadLoading"
              style="height: 300px; overflow-y: auto;border: 1px solid #ccc;"
              v-model="shopModalForm.detail"
              :defaultConfig="editorConfig"
              :defaultHtml="shopModalForm.detail"
              :mode="mode"
              @onCreated="onCreated"
          />
        </template>
        <template v-slot:serveExplain>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeServeExplain"
              :uploadResponse="handleServeExplain"
              :fileList.sync="serveExplainFileList"
          >
          </ch-upload-img>
        </template>
        <template v-slot:chargeExplain>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeChargeExplain"
              :uploadResponse="handleChargeExplain"
              :fileList.sync="chargeExplainFileList"
          >
          </ch-upload-img>
        </template>
      </ch-form>
    </div>
    <tag-library ref="serviceTag" @selectTagList="changeService"/>
    <commodity-watch ref="commodityWatch" />
  </div>
</template>

<script>
import {Message} from "element-ui";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import req from "@/components/Upload/request"
import '@wangeditor/editor/dist/css/style.css'
import {mapActions, mapGetters} from "vuex";
import {regionData} from "element-china-area-data";
import Vue from "vue";
import dayjs from "dayjs";
import _ from "lodash";
const tagLibrary = () => import("./tagLibrary.vue")
const commodityWatch = () => import("./commodityWatch.vue")
const templateSpec = () => import("./template/index.vue")

export default {
  name: "commodityEdit",
  components: { Editor, Toolbar, tagLibrary,commodityWatch,templateSpec },
  data(){
    return{
      isTableLoading:false,
      infoModalForm:{
        coverUrl:'',
        firstClassifyId: null,
        classifyId: null,
        goodsName: '',
        pushClassify: null,
        classifyIds: [],
        payType:null,
        goodsAbbreviation:'',
        decorateUrl: '',
      },
      specifyModalForm:{
        specId:null,
        specName:'',
        serviceTime:null,
        overtimePay:'',
        city:[],
        specDetail:'',
      },
      payType:[],
      shopModalForm:{
        serveExplain: '',
        chargeExplain: '',
      },
      infoRules:{
        coverUrl: [{ required: true, message: "请上图片", trigger: ["blur", "change"] }],
        classifyIds: [{ required: true, message: "请选择服务", trigger: ["blur", "change"] }],
        goodsName: [{ required: true, message: "请填写服务名称", trigger: ["blur", "change"] }],
        goodsAbbreviation: [{ required: true, message: "请填写简称", trigger: ["blur", "change"] }],
        pushClassify: [{ required: true, message: "请选择", trigger: ["blur", "change"] }],
        city: [{ required: true, message: "请选择", trigger: ["blur","change"] }],
      },
      specifyRules:{
        type: [{ required: true, message: "请选择", trigger: ["blur", "change"] }],
        specName: [{ required: true, message: "请输入", trigger: ["blur", "change"] }],
        serviceTime: [{ required: true, message: "请输入", trigger: ["blur",] }],
        overtimePay: [{ required: true, message: "请输入", trigger: ["blur"] }],
      },
      shopRules:{
        mainImageUrl: [{ required: true, message: "请上传图片", trigger: ["blur", "change"] }],
        detail: [{ required: true, message: "请填写", trigger: ["blur", "change"] }],
      },
      selectedOptions: [],
      unavailableTimeType:[],
      dates:[],
      dateSelect:[],
      showSelect:false,
      select:'',
      butLoading:false,
      saveLoading:false,
      tags:'',
      secondList:[],
      firstList:[],
      tailList:[],
      uploadFileList:[],
      shopMainFileList:[],
      shopMainArrays:[],
      serveExplainFileList:[], // 服务说明
      chargeExplainFileList: [], // 收费说明
      tagsList:[],
      selectTags:[],
      serviceList:[],
      serviceDet:[
        {text:''}
      ],
      serviceContent:[
        {
          specName:'',
          specDetail:'',
          serviceDet:[
            {text:''}
          ],
          stipulate:'',
          once: '',
          onceOriginalCost: '',
          twice: '',
          twiceOriginalCost: '',
          three: '',
          threeOriginalCost: '',
        }
      ],
      tableData:[],
      editor: null,
      remarks: '',  // 备注 内容
      toolbarConfig: { },
      editorConfig: {
        readOnly: false,
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            customUpload: (file, insertFn) => {
              this.editorUploadLoading = true
              file.uid = file.lastModified
              let formData = new FormData()
              formData.append("file", file)
              req.upload('/hm/general/upload', formData).then((res) => {
                insertFn(res.data.url, file.name, res.data.path)
              }).finally(()=>{
                this.editorUploadLoading = false
              })
            }
          }
        },
      },
      mode: 'default', // or 'simple'
      id:null,
      editorUploadLoading: false,
      hmGoodsSiteSet:[],
      hmGoodsSiteSetStr:'',
      treeList:[],
      templateId:null,
      night:{},
      activityList:[], // 平台活动列表
      activityIds:[], // 选中的平台活动id
      curActivityObj:[], // 当前选择的平台活动id
      activityGoodsList:[], // 平台活动商品列表
      activityModalFormOne: {}, // 平台活动表单
      activityGoodsListOne:{}, // 平台活动商品列表
      activityModalFormTwo: {}, // 平台活动表单
      activityGoodsListTwo:{}, // 平台活动商品列表
      imgTemplateList: [], // 头图模板列表
      imgTemplateId: null, // 头图模板id
    }
  },
  computed:{
    infoOption: function () {
      return [
        {type: 'slot',  label: '商品封面图：', slotName: 'upload',prop: 'coverUrl' },
        {type: 'slot',  label: '服务分类：', slotName: 'optionCascade',prop: 'classifyIds' },
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: '服务名称最多只能输入20个字',  props:{showWordLimit:true,maxlength:20} },
        {type: 'input', label: '简称：', prop: 'goodsAbbreviation', placeholder: '服务简称最多只能输入10个字',  props:{showWordLimit:true,maxlength:10} },
        {type: 'input', label: '自定义编号：', prop: 'customNumber', placeholder: '最多只能输入10个字',  props:{showWordLimit:true,maxlength:10} },
        {type: 'input', label: '推广Tip：', prop: 'tip', placeholder: '请描述服务特性，最多只能输入12个字',  props:{showWordLimit:true,maxlength:12} },
        {type: 'slot',  label: '服务标签：', slotName: 'tags', prop: 'tag'},
        {type: 'select', label: '推广位置：', prop: 'pushClassify', placeholder: '请选择',
          name: "name", value: "id", option: this.tailList},
        {type: 'slot',  label: '适用城市区域：', slotName: 'city' ,prop: 'city',},
        {type: 'slot',  label: '不可使用日期：', slotName: 'unDate' ,prop: 'date',},
        {type: 'date', label: '自动下架时间', prop: 'expiredTime', placeholder: '请选择自动下架时间',props: {type: 'datetime'}},
        {type: 'slot',  label: '支付方式：', slotName: 'payType' ,prop: 'payType',},
        {type: 'slot',  label: '平台活动：', slotName: 'activityIds', show: this.activityList.length>0},
      ]
    },
    shopOption: function () {
      return [
        {type: 'slot',  label: '商品主图：', slotName: 'shopMainImage',prop: 'mainImageUrl'},
        {type: 'slot',  label: '商品描述：', slotName: 'shopDescript',prop: 'detail'},
        {type: 'slot', label: '服务说明：',slotName: 'serveExplain', prop: 'serveExplain'},
        {type: 'slot', label: '收费说明：',slotName: 'chargeExplain', prop: 'chargeExplain'},
      ]
    },
    activityOptionOne: function () {
      if(this.activityGoodsListOne?.fillBy === 0){
        // 平台控制
        return [
          {type: 'input', label: '参与平台活动商品：', prop: 'name', placeholder: '请输入活动名称',  props:{readonly:true} },
          {type: 'radio', label: '优惠方式类型：', prop: 'discountType', option: this.discountTypeOptionsOne, },
          {type: 'text', label: '使用门槛：', prop: '_discountThreshold', placeholder: '请输入门槛',  },
          this.discountTypeOne,
          {type: 'input', label: '发放总数：', prop: 'total', placeholder: '请输入发放总数',  },
          {type: 'input', label: '每日限制：', prop: 'dailyLimit', placeholder: '请输入发放总数',  },
          {type: 'text', label: '是否可叠加优惠：', prop: 'isStackable', yes: '是',no: '否'  },
          {type: 'text', label: '用户范围：', prop: 'userRange',  },
        ]
      }else {
        // 商家控制
        return [
          {type: 'input', label: '参与平台活动：', prop: 'name', placeholder: '请输入活动名称',  props:{readonly:true} },
          {type: 'radio', label: '优惠方式类型：', prop: 'discountType', option: this.discountTypeOptionsOne, },
          {type: 'radio', label: '使用门槛：', prop: 'discountThresholdType',
            option: [
              {label: '不限制', value: '1'},
              {label: '满额使用', value: '2'},
            ], },
          {type: 'number', label: '满减金额：', prop: 'discountThreshold',props:{disabled: this.isDisabled},
            placeholder: '请输入消费满多少可用', precision: 2,show: this.activityModalFormOne.discountThresholdType == 2,},
          this.discountTypeOne,
          {type: 'input', label: '发放总数：', prop: 'total', placeholder: '请输入发放总数',  },
          {type: 'input', label: '每日限制：', prop: 'dailyLimit', placeholder: '请输入发放总数',  },
          {type: 'text', label: '是否可叠加优惠：', prop: 'isStackable', yes: '是',no: '否'  },
          {type: 'text', label: '用户范围：', prop: 'userRange',  },
        ]
      }
    },
    activityOptionTwo: function () {
      if(this.activityGoodsListTwo?.fillBy === 0){
        // 平台控制
        return [
          {type: 'input', label: '参与平台活动商品：', prop: 'name', placeholder: '请输入活动名称',  props:{readonly:true} },
          {type: 'radio', label: '优惠方式类型：', prop: 'discountType', option: this.discountTypeOptionsTwo, },
          {type: 'text', label: '使用门槛：', prop: '_discountThreshold', placeholder: '请输入门槛',  },
          this.discountTypeTwo,
          {type: 'input', label: '发放总数：', prop: 'total', placeholder: '请输入发放总数',  },
          {type: 'input', label: '每日限制：', prop: 'dailyLimit', placeholder: '请输入发放总数',  },
          {type: 'text', label: '是否可叠加优惠：', prop: 'isStackable', yes: '是',no: '否'  },
          {type: 'text', label: '用户范围：', prop: 'userRange',  },
        ]
      }else {
        // 商家控制
        return [
          {type: 'input', label: '参与平台活动：', prop: 'name', placeholder: '请输入活动名称',  props:{readonly:true} },
          {type: 'radio', label: '优惠方式类型：', prop: 'discountType', option: this.discountTypeOptionsTwo, },
          {type: 'radio', label: '使用门槛：', prop: 'discountThresholdType',
            option: [
              {label: '不限制', value: '1'},
              {label: '满额使用', value: '2'},
            ], },
          {type: 'number', label: '满减金额：', prop: 'discountThreshold',props:{disabled: this.isDisabled},
            placeholder: '请输入消费满多少可用', precision: 2,show: this.activityModalFormTwo.discountThresholdType === '2',},
          this.discountTypeTwo,
          {type: 'input', label: '发放总数：', prop: 'total', placeholder: '请输入发放总数',  },
          {type: 'input', label: '每日限制：', prop: 'dailyLimit', placeholder: '请输入发放总数',  },
          {type: 'text', label: '是否可叠加优惠：', prop: 'isStackable', yes: '是',no: '否'  },
          {type: 'text', label: '用户范围：', prop: 'userRange',  },
        ]
      }
    },
    discountTypeOptionsOne: function () {
      let type
      if(this.activityGoodsListOne?.activity){
        type = this.activityGoodsListOne.activity.discountType.split(',')
      }else {
        type = this.activityGoodsListOne && this.activityGoodsListOne.discountType.split(',')
      }
      if(type &&type.length>1){
        return [
          {label: '减免金额', value: '1'},
          {label: '折扣', value: '2'},
        ]
      }else {
        if(type && type.includes('1')){
          return [
            {label: '减免金额', value: '1'},
          ]
        }else {
          return [
            {label: '折扣', value: '2'},
          ]
        }
      }
    },
    discountTypeOptionsTwo: function () {
      let type
      if(this.activityGoodsListTwo?.activity){
        type = this.activityGoodsListTwo.activity.discountType.split(',')
      }else {
        type = this.activityGoodsListTwo && this.activityGoodsListTwo.discountType.split(',')
      }
      if(type &&type.length>1){
        return [
          {label: '减免金额', value: '1'},
          {label: '折扣', value: '2'},
        ]
      }else {
        if(type && type.includes('1')){
          return [
            {label: '减免金额', value: '1'},
          ]
        }else {
          return [
            {label: '折扣', value: '2'},
          ]
        }
      }
    },
    discountTypeOne: function () {
      let type = this.activityModalFormOne.discountType
      let disabled = this.activityModalFormOne.fillBy === 0
      if(type == 1){
        return {type: 'number', label: '减免金额：', prop: 'discountValue',props:{ disabled: disabled}, placeholder: '请输入减免金额',precision: 2}
      } else if (type == 2) {
        return {type: 'number', label: '折扣：', prop: 'discountValue',props:{ disabled: disabled}, placeholder: '请输入1 ~ 9.9之间的数字',precision: 2}
      } else {
        return {type: 'number', label: '折扣：', prop: 'discountValue', show: false}
      }
    },
    discountTypeTwo: function () {
      let type = this.activityModalFormTwo.discountType
      let disabled = this.activityModalFormTwo.fillBy === 0
      if(type == 1){
        return {type: 'number', label: '减免金额：', prop: 'discountValue',props:{ disabled: disabled}, placeholder: '请输入减免金额',precision: 2}
      } else {
        return {type: 'number', label: '折扣：', prop: 'discountValue',props:{ disabled: disabled}, placeholder: '请输入1 ~ 9.9之间的数字',precision: 2}
      }
    },
    activityRulesOne: function () {
      return {
        discountType: [{ required: true, message: "请选择优惠方式类型", trigger: ["blur", "change"] }],
        discountThreshold: [{ required: true, message: "请输入使用门槛", trigger: ["blur"] }],
        discountValue: [{ required: true, message: "请输入优惠金额", trigger: ["blur"] }],
        total: [{ required: true, message: "请输入发放总数", trigger: ["blur"] }],
        dailyLimit: [{ required: true, message: "请输入每日限制", trigger: ["blur"] }],
      }
    },
    activityRulesTwo: function () {
      return {
        discountType: [{ required: true, message: "请选择优惠方式类型", trigger: ["blur", "change"] }],
        discountThreshold: [{ required: true, message: "请输入使用门槛", trigger: ["blur"] }],
        discountValue: [{ required: true, message: "请输入优惠金额", trigger: ["blur"] }],
        total: [{ required: true, message: "请输入发放总数", trigger: ["blur"] }],
        dailyLimit: [{ required: true, message: "请输入每日限制", trigger: ["blur"] }],
      }
    },
    options: function () {
      const arr = this.userInfo?.areaIdsSet || []
      const newRegionData = regionData.map(item=>{
        item.disabled = !arr.includes(item.value)
        item.children = item.children?.map(child=>{
          child.disabled = !arr.includes(child.value)
          let districtStr = []
          if(!child.disabled){
            districtStr = arr.filter(areaId=>areaId.startsWith(child.value))
          }
          if(districtStr.length > 1){
            child.children = child.children?.map(childItem=>{
              childItem.disabled =  !arr.includes(childItem.value)
              return {...childItem}
            })
          }else {
            child.children = child.children?.map(childItem=>{
              childItem.disabled = false
              return {...childItem}
            })
          }
          return {...child}
        })
        return {...item}
      })
      return newRegionData.sort((a,b)=>{
        if(a.disabled && b.disabled){
          return 0
        }
        return a.disabled ? 1 : -1;
      })
    },
    watchObj: function (){
      const tableData = this.$refs.templateSpec.getTableDate();
      let hmGoodsSpecVoList = tableData.map(item=>{
        if(this.specifyModalForm.specId===1){
          return {
            ...item,
            specDetail:this.specifyModalForm.specDetail,
          }
        }else{
          return {
            ...item,
            serveDetailList:item.serviceDet?.map(item => item.text)||[]
          }
        }
      })
      let decorateUrl = this.imgTemplateId ? this.imgTemplateList.filter(item=>item.id===this.imgTemplateId)[0].imgUrl : ''
      // this.dates.map(item => dayjs(item).format('YYYY-MM-DD')).join(',')
      let unavailableTimeStr = this.dates.map(item => dayjs(item).format('YYYY-MM-DD')).join(',')
      return {
        ...this.specifyModalForm, // 规格类型 规格名称 服务时长 规格描述 服务明细
        ...this.infoModalForm, // 服务名称 推广Tip 商品封面图
        ...this.shopModalForm, // 主图 服务说明 收费说明
        tag:this.selectTags,  // 服务标签
        hmGoodsSiteSet:this.hmGoodsSiteSet,  // 适用城市
        unavailableTimeType:this.unavailableTimeType, // 不可用 类型
        unavailableTimeStr: unavailableTimeStr, // 不可以日期
        detail:this.shopModalForm.detail, // 商品描述
        hmGoodsSpecVoList:hmGoodsSpecVoList,
        decorateUrl:decorateUrl, // 头图模板
      }
    },
    ...mapGetters(['userInfo'])
  },
  watch:{
    dates: function (){
      if(!this.dates){
        this.dateSelect = []
      }
    },
    unavailableTimeType: _.debounce(function (){
      if(!this.unavailableTimeType.includes(3)){
        this.dateSelect = []
        this.dates = []
      }
    },500),
    activityIds: function (){
      this.curActivityObj = this.activityList.map(item => {
        if(this.activityIds.includes(item.id)){
          return {
            name: item.name,
            activityId: item.id,
          }
        }
      }).filter(item=>item && item.name)
    }
  },
  activated(){
    this.id = Number(this.$route.query.id)
    this.openModal()
    this.getInfo()
    this.getActivities()
  },
  methods:{
    ...mapActions(["closeTag"]),
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 打开前处理
    openModal() {
      this.$refs.infoForm.clearValidate();
      this.$refs.shopForm.clearValidate();
      this.init()
    },
    // 详情
    getInfo(){
      this.$curl.post('/hm/goods/info/'+this.id).then(res=>{
        this.templateId = res.data.templateId
        this.infoModalForm = res.data
        delete this.infoModalForm.status
        this.shopModalForm = res.data.hmGoodsDetail
        this.uploadFileList =  res.data.coverUrl ?[{url: res.data.coverUrl}]:[]
        this.night.nightService = res.data.hmGoodsDetail.nightService
        this.night.raisePriceType = res.data.hmGoodsDetail.raisePriceType
        this.night.raisePrice = res.data.hmGoodsDetail.raisePrice
        if(res.data.hmGoodsDetail?.mainImageUrl){
          this.shopMainFileList = res.data.hmGoodsDetail?.mainImageUrl.split(';').map(item => {
            return {url: item}
          })
          this.shopMainArrays = res.data.hmGoodsDetail?.mainImageUrl.split(';').map(item => {
            return {url: item}
          })
        }
        if (res.data.hmGoodsDetail.serveExplain) {
          this.serveExplainFileList = [{url:res.data.hmGoodsDetail.serveExplain}]
        }
        if(res.data.hmGoodsDetail.chargeExplain){
          this.chargeExplainFileList = [{url:res.data.hmGoodsDetail.chargeExplain}]
        }
        this.infoModalForm.classifyIds = [this.infoModalForm.firstClassifyId,this.infoModalForm.classifyId]
        if(res.data.payType){
          this.payType = res.data.payType.split(',')
        }
        this.unavailableTimeType = res.data.hmGoodsDetail.unavailableTimeType.split(',').map(Number).filter(item=>item)
        const goodsSiteStr = JSON.parse(res.data.hmGoodsDetail.goodsSiteStr)
        let selectedOptions = []
        this.options.forEach(item=>{
          goodsSiteStr.forEach(areaIdItem=>{
            if(item.value === areaIdItem[0]){
              item.children.forEach(cityItem=>{
                if(cityItem.value === areaIdItem[1]){
                  if(!areaIdItem[2]) {
                    cityItem.children.forEach(districtItem=>{
                      selectedOptions.push([item.value,cityItem.value,districtItem.value])
                    })
                  }else {
                    selectedOptions.push([item.value,cityItem.value,areaIdItem[2]])
                  }
                }
              })
            }
          })
        })
        Vue.set(this.infoModalForm, 'city', selectedOptions)
        this.hmGoodsSiteSetStr = res.data.hmGoodsDetail.goodsSiteStr
        this.hmGoodsSiteSet = res.data.hmGoodsSiteSet
        this.dates = res.data.hmGoodsDetail.unavailableTimeStr.split(',')
        this.dateSelect = this.dates

        // 服务标签
        this.selectTags = res.data.tag && res.data.tag?.split(',') || []
        this.tagsList = this.selectTags.map(item => {
          return {
            name:item
          }
        })
        this.serviceList = this.selectTags
        this.specifyModalForm.specId = res.data.specId
        this.specifyModalForm.hmGoodsSiteSet = res.data.hmGoodsSiteSet
        this.specifyModalForm.overtimePay = res.data.overtimePay
        this.specifyModalForm.minPrice = res.data.minPrice
        if(res.data.templateId===1) { // 基础
          this.specifyModalForm.specName = res.data.hmGoodsSpecVoList[0]?.specName || ''
          this.specifyModalForm.specDetail = res.data.hmGoodsSpecVoList[0]?.specDetail || ''
          this.specifyModalForm.serviceTime = res.data.serviceTime && res.data.serviceTime.split(',').map(item=>Number(item))
          if(res.data.hmGoodsSpecVoList[0]?.serveDetailList){
            this.serviceDet = res.data.hmGoodsSpecVoList[0].serveDetailList.length>0 ? res.data.hmGoodsSpecVoList[0].serveDetailList.map(item=>{
              return {text:item}
            }) : [{text: ''}]
          }
          this.specifyModalForm.serviceDet = this.serviceDet
          this.tableData = res.data.hmGoodsSpecVoList

        }else if(res.data.templateId===2){
          this.tableData = res.data.hmGoodsSpecVoList
          this.serviceContent = res.data.hmGoodsSpecVoList.map(item=>{
            return {
              ...item,
              serviceDet: item.serveDetailList.length>0 ? item.serveDetailList?.map(item=>{
                return {text:item}
              }) : [{text: ''}],
              minPrice: this.specifyModalForm.minPrice
            }
          })
          this.tailList = this.serviceContent
        }

        // 平台活动
        if(res.data.activityGoodsList && res.data.activityGoodsList.length>0){
          this.activityIds = res.data.activityGoodsList.map(item=>item.activityId)
          this.activityGoodsList = res.data.activityGoodsList
          this.activityModalFormOne = Object.assign({},res.data.activityGoodsList[0])
          this.activityGoodsListOne = Object.assign({},res.data.activityGoodsList[0])
          this.activityModalFormOne.name = this.activityModalFormOne.activity.name
          this.activityModalFormOne.fillBy = this.activityModalFormOne.activity.fillBy
          this.activityModalFormOne.discountType = String(this.activityModalFormOne.discountType)
          this.activityModalFormOne.userRange = ['','未购用户','已购用户','不限制'][this.activityModalFormOne.activity.userRange]
          const {discountThreshold,discountValue} = this.activityModalFormOne
          if(this.activityModalFormOne.discountType==='1'){
            this.activityModalFormOne._discountThreshold = discountThreshold === 0 ? '不限制': `满${discountThreshold}元减${discountValue}元`
          }else {
            this.activityModalFormOne._discountThreshold = discountThreshold === 0 ? '不限制': `满${discountThreshold}元打${discountValue}折`
          }
          let discountThresholdType = this.activityModalFormOne.discountThreshold === 0 ? '1': '2'
          Vue.set(this.activityModalFormOne, 'discountThresholdType', discountThresholdType)
          delete this.activityModalFormOne.goodsId
        }
        if(res.data.activityGoodsList && res.data.activityGoodsList.length > 1) {
          this.activityModalFormTwo = Object.assign({},res.data.activityGoodsList[1])
          this.activityGoodsListTwo = Object.assign({},res.data.activityGoodsList[1])
          this.activityModalFormTwo.name = this.activityModalFormTwo.activity.name
          this.activityModalFormTwo.fillBy = this.activityModalFormTwo.activity.fillBy
          this.activityModalFormTwo.discountType = String(this.activityModalFormTwo.discountType)
          this.activityModalFormTwo.userRange = ['','未购用户','已购用户','不限制'][this.activityModalFormTwo.activity.userRange]
          const {discountThreshold,discountValue} = this.activityModalFormTwo
          if(this.activityModalFormTwo.discountType==='1'){
            this.activityModalFormTwo._discountThreshold = discountThreshold === 0 ? '不限制': `满${discountThreshold}元减${discountValue}元`
          }else {
            this.activityModalFormTwo._discountThreshold = discountThreshold === 0 ? '不限制': `满${discountThreshold}元打${discountValue}折`
          }
          let discountThresholdType = this.activityModalFormTwo.discountThreshold === 0 ? '1': '2'
          Vue.set(this.activityModalFormTwo, 'discountThresholdType', discountThresholdType)
          delete this.activityModalFormTwo.goodsId
        }
      })
    },
    async init(){
      await this.getTailList()
      if(this.$route.query.id){
        await this.getTemplateTree(this.$route.query.id)
      }else {
        await this.getTreeList()
      }
      await this.getImgTemplateList()
    },
    tagChange(event){
      const len = event.length
      if(event[len-1].length>6){
        this.selectTags.pop()
        this.$message.warning('长度超过6个字符，请重新输入')
      }
    },
    handleChangeClassify(val){ // 服务分类
      if(!val) return;
      this.$refs.infoForm.clearValidate('classifyIds')
      const firstClassifyId = val[0]
      const classifyId = val[1]
      this.infoModalForm.firstClassifyId = firstClassifyId || null
      this.infoModalForm.classifyId = classifyId || null
      if(!firstClassifyId) {
        this.specifyModalForm.specId = null
        return
      }
      const specId = this.treeList.filter((item)=>item.id===firstClassifyId)[0].children.filter(item=>item.id === classifyId)[0].specId
      this.specifyModalForm.specId = specId
    },
    openServiceTags(){
      this.$refs?.serviceTag.openTagsList(this.serviceList)
    },
    changeService(val){
      this.serviceList = val
      this.selectTags = []
      this.tagsList =  Object.values(val.reduce((acc, item) => {
        // 如果 id 不在 accumulator 对象中，添加它
        if (!acc[item.id]) {
          acc[item.id] = item;
        }
        return acc;
      }, {}));
    },
    // 树
    async getTreeList(){
      this.$curl.get('/hm/classify/tree').then(res=>{
        this.treeList = res.data
      })
    },
    // 根据商品获取同一规格下的分类树 /hm/classify/templateTree/{goodsId}
    async getTemplateTree(goodsId){
      this.$curl.get('/hm/classify/templateTree/'+goodsId).then(res=>{
        this.treeList = res.data
      })
    },
    async getTailList(){
      await this.$curl.get('/hm/classify/tailList').then(res=>{
        this.tailList = res.data
      })
    },
    // 头图模板
    async getImgTemplateList(){
      this.$curl.post('/hm/hmImgTemplate/list',{}).then(res=>{
        this.imgTemplateList = res.rows
        if(this.infoModalForm.decorateUrl){
          this.imgTemplateId = this.imgTemplateList.filter(item=>item.imgUrl===this.infoModalForm.decorateUrl)[0].id
        }
      })
    },
    handleUpload(res) {
      this.infoModalForm.coverUrl = res.data.url
      this.$refs.infoForm.clearValidate('coverUrl')
    },
    removeUpload() {
      this.infoModalForm.coverUrl = ''
      this.shopMainArrays = this.shopMainArrays.slice(1)
    },
    handleShopMain(res,file) {
      this.shopMainArrays.push({
        id: res.data.id,
        uid: file.uid,
        url: res.data.url,
      });
      this.shopModalForm.mainImageUrl = this.shopMainArrays.map(item => item.url).join(';')
      this.$refs.shopForm.clearValidate('mainImageUrl')
    },
    removeShopMain(file) {
      this.shopMainArrays = this.shopMainFileList.filter((item) => {
          return item.uid !== file.uid;
      });
      this.shopModalForm.mainImageUrl = this.shopMainArrays.map(item => item.url).join(';')
    },
    handleServeExplain(res){
      this.shopModalForm.serveExplain = res.data.url
      this.$refs.shopForm.clearValidate('serveExplain')
    },
    removeServeExplain() {
      this.shopModalForm.serveExplain = ''
    },
    handleChargeExplain(res){
      this.shopModalForm.chargeExplain = res.data.url
      this.$refs.shopForm.clearValidate('chargeExplain')
    },
    removeChargeExplain(){
      this.shopModalForm.chargeExplain = ''
    },
    checkForm(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve();
          } else reject();
        });
      });
    },
    // 选择城市
    handleChange() {
      let checkedNodeList = this.$refs.city.getCheckedNodes();
      checkedNodeList = checkedNodeList.filter(item => !(item.parent&&item.parent.checked))
      this.infoModalForm.hmGoodsSiteSet = checkedNodeList.map(item=>{
        if(item.level===1 && item.children[0].label!=='市辖区'){
          let arr = []
          item.children.forEach(v=>{
            if(v.checked) {
              v.areaObj = {
                province: item.label,
                provinceId: item.value,
                cityId: v.value,
                city: v.label,
                district: v.label,
              }
              arr.push(v.areaObj)
            }
          })
          return arr;
        }else if(item.level===1&&item.children[0].label==='市辖区'){
          return {
            province: item.label,
            provinceId: item.value,
            cityId: item.children[0].value,
            city: item.label,
            district: item.label,
          }
        }
        if(item.level===2){ // 市 全选市，区的值给市的值
          return {
            province:item.pathLabels[0],
            city:item.pathLabels[1],
            district:item.pathLabels[1],
            provinceId:item.path[0],
            cityId:item.path[1],
          }
        }
        if(item.level===3&&item.parent.label!=='市辖区'){
          return{
            province:item.pathLabels[0],
            city:item.pathLabels[1],
            district:item.pathLabels[2],
            provinceId:item.path[0],
            cityId:item.path[1],
            districtId:item.path[2],
          }
        } else if(item.level===3&&item.parent.label==='市辖区'){
          return {
            province:item.pathLabels[0],
            city:item.pathLabels[0],
            district:item.pathLabels[2],
            provinceId:item.path[0],
            cityId:item.path[0],
            districtId:item.path[2],
          }
        }
      })
      this.infoModalForm.hmGoodsSiteSet =  this.infoModalForm.hmGoodsSiteSet.flat()
      this.hmGoodsSiteSet = this.infoModalForm.hmGoodsSiteSet
      const hmGoodsSiteSetStr = this.hmGoodsSiteSet.map(item=> [item.provinceId,item.cityId,item.districtId || ''])
      this.hmGoodsSiteSetStr = JSON.stringify(hmGoodsSiteSetStr)
    },
    // 不可使用日期
    onChangeDate(){
      if(this.dates){
        this.dateSelect = this.dates.map((item) => dayjs(item).format('YYYY-MM-DD'))
      }else{
        this.dateSelect = []
      }
    },
    submitForm(){
      let list = [
        this.checkForm("infoForm"),
        this.checkForm("shopForm"),
      ];
      if(this.$refs.activityFormOne) {
        list.push(this.checkForm("activityFormOne"))
      }
      if(this.$refs.activityFormTwo) {
        list.push(this.checkForm("activityFormTwo"))
      }
      this.butLoading = true
      Promise.all(list)
          .then(() => {
            this.specifyModalForm = this.$refs.templateSpec.getDate()
            this.tableData = this.$refs.templateSpec.getTableDate() || []
            const nightServe = this.$refs.templateSpec.getNightServe()
            this.infoModalForm.payType = this.payType.join(',')
            let params = {
              ...this.infoModalForm,
              ...this.specifyModalForm,
            }
            params.activityGoodsList = []
            params.tag = this.selectTags?.join(',') || ''
            if(this.specifyModalForm.serviceTime){
              params.serviceTime = this.specifyModalForm.serviceTime.sort().join(',')
            }
            params.hmGoodsDetail = this.shopModalForm
            params.hmGoodsDetail.nightService = nightServe.nightService
            params.hmGoodsDetail.raisePriceType = nightServe.raisePriceType
            params.hmGoodsDetail.raisePrice = nightServe.raisePrice
            params.minPrice = this.tableData[0].minPrice || this.specifyModalForm.minPrice
            params.hmGoodsSpecVoList = this.tableData.map(item=>{
              if(this.specifyModalForm.specId===1){
                return {
                  ...item,
                  serveDetailList: this.serviceDet.map(item => item.text),
                  specDetail: this.specifyModalForm.specDetail,
                }
              }else{
                return {
                  ...item,
                  serveDetailList: item.serviceDet?.map(item => item.text)
                }
              }

            })
            params.hmGoodsSiteSet = this.hmGoodsSiteSet
            params.hmGoodsDetail.goodsSiteStr = this.hmGoodsSiteSetStr
            if(this.unavailableTimeType.includes(3)&&!this.dates){
              Message({type: "error", message: "请选择不可使用日期!",})
              return
            }
            params.hmGoodsDetail.unavailableTimeType = this.unavailableTimeType.join(',')
            params.hmGoodsDetail.unavailableTimeStr = this.dates.map(item => dayjs(item).format('YYYY-MM-DD')).join(',')

            //平台活动
            if(this.activityModalFormOne.name){
              const discountThreshold = this.activityModalFormOne.discountThresholdType == 2 ? this.activityModalFormOne.discountThreshold : 0
              this.activityModalFormOne.discountThreshold = discountThreshold
              params.activityGoodsList = [this.activityModalFormOne]
            }
            if(this.activityModalFormTwo.name){
              const discountThreshold = this.activityModalFormOne.activityModalFormTwo == 2 ? this.activityModalFormTwo.discountThreshold : 0
              this.activityModalFormTwo.discountThreshold = discountThreshold
              params.activityGoodsList.push(this.activityModalFormTwo)
            }
            this.$curl.post('/hm/goods/edit',params).then( () =>{
              this.butLoading = false
              this.closeModal()
              Message({type: "success", message: "编辑成功!",})
              this.closeTag({ routerName: this.$route.name });
              this.$router.push({
                name: 'commodity'
              })
            })
          })
          .finally(() => {
            this.butLoading = false
          });
    },
    saveDraft(){
      this.specifyModalForm.payType = this.payType.join(',') || ''
      let params = {
        ...this.infoModalForm,
        ...this.specifyModalForm,
      }
      params.tag = this.selectTags?.join(',') || ''
      if(this.specifyModalForm.serviceTime){
        params.serviceTime = this.specifyModalForm.serviceTime.sort().join(',')
      }
      params.hmGoodsDetail = this.shopModalForm
      if(this.specifyModalForm.specId===1){
        params.hmGoodsSpecVoList = this.tableData.map(item=>{
          return {
            ...item,
            serveDetailList:this.serviceDet.map(item => item.text),
            specDetail:this.specifyModalForm.specDetail,
          }
        })
      }else{
        params.hmGoodsSpecVoList = this.tableData.map(item =>{
          return {
            ...item,
            serveDetailList:item.serviceDet.map(item => item.text)
          }
        })
      }
      params.hmGoodsSiteSet = this.hmGoodsSiteSet
      params.hmGoodsDetail.goodsSiteStr = this.hmGoodsSiteSetStr
      if(this.unavailableTimeType.includes(3)&&!this.dates){
        Message({type: "error", message: "请选择不可使用日期!",})
        return
      }
      params.hmGoodsDetail.unavailableTimeType = this.unavailableTimeType.join(',')
      params.hmGoodsDetail.unavailableTimeStr = this.dates.map(item => dayjs(item).format('YYYY-MM-DD')).join(',')
      this.saveLoading = true
      this.$curl.post('/hm/goods/saveDraft',params).then( () =>{
        this.closeModal()
        this.closeTag({ routerName: this.$route.name });
        Message({type: "success", message: "保存草稿成功!",})
        this.$router.push({
          name: 'commodity'
        })
      }).finally(()=>{
        this.saveLoading = false
      })

    },

    // 可参加的活动
    async getActivities(){
      this.$curl.get('/hm/hmActivity/list').then(res=>{
        this.activityList = res.data
      })
    },
    handleActivityChange(val){
      if(val && val.length>0){
        if(!this.activityModalFormOne.activityId){
          let oneVal = val.filter(item=>{return item!==this.activityModalFormTwo.activityId})[0]
          this.disposeActivityDataOne(oneVal)
        } else if (!val.includes(this.activityModalFormOne.activityId)){
          this.activityModalFormOne = {}
          this.activityGoodsListOne = {}
        }
        if(!this.activityModalFormTwo.activityId && val.length>1){
          let twoVal = val.filter(item=>{return item!==this.activityModalFormOne.activityId})[0]
          this.disposeActivityDataTwo(twoVal)
        } else if (!val.includes(this.activityModalFormTwo.activityId)){
          this.activityModalFormTwo = {}
          this.activityGoodsListTwo = {}
        }
      }else {
        this.activityModalFormOne = {}
        this.activityModalFormTwo = {}
      }
    },
    disposeActivityDataOne(one){
      this.activityGoodsListOne = this.activityList.filter(item => item.id === one)[0]
      const {id,discountThreshold,discountValue,discountType,userRange,fillBy,name,isStackable} = this.activityGoodsListOne
      let _discountThreshold = ''
      if(discountType === '1'){
        _discountThreshold = discountThreshold == 0 ? '不限制': `满${discountThreshold}元减${discountValue}元`
      }else if(discountType === '2'){
        _discountThreshold = discountThreshold == 0 ? '不限制': `满${discountThreshold}元打${discountValue}折`
      }
      this.activityModalFormOne = {
          activityId: id,
          dailyLimit: null,
          discountType: discountType,
          _discountThreshold: _discountThreshold,
          discountThreshold: fillBy === 0 ? discountThreshold : null,
          discountValue: fillBy === 0 ? discountValue : null,
          total: null,
          userRange: ['','未购用户','已购用户','不限制'][userRange],
          fillBy:fillBy,
          name: name,
          discountThresholdType: null,
          isStackable
      }
    },
    disposeActivityDataTwo(two){
      this.activityGoodsListTwo = this.activityList.filter(item => item.id === two)[0]
      const {id,discountThreshold,discountValue,discountType,userRange,fillBy,name,isStackable} = this.activityGoodsListTwo
      let _discountThreshold = ''
      if(discountType === '1'){
        _discountThreshold = discountThreshold == 0 ? '不限制': `满${discountThreshold}元减${discountValue}元`
      }else if(discountType === '2'){
        _discountThreshold = discountThreshold == 0 ? '不限制': `满${discountThreshold}元打${discountValue}折`
      }
      this.activityModalFormTwo = {
        activityId: id,
        dailyLimit: null,
        discountType: discountType,
        _discountThreshold: _discountThreshold,
        discountThreshold: fillBy === 0 ? discountThreshold : null,
        discountValue: fillBy === 0 ? discountValue : null,
        total: null,
        userRange: ['','未购用户','已购用户','不限制'][userRange],
        fillBy:fillBy,
        name: name,
        discountThresholdType: null,
        isStackable
      }
    },
    handleImgTemplate(item){
      if(this.imgTemplateId === item.id){
        this.infoModalForm.decorateUrl = ''
        this.imgTemplateId = ''
        return
      }
      this.imgTemplateId = item.id
      this.infoModalForm.decorateUrl = item.imgUrl
    },
    closeModal(){
        this.id = null
        this.infoModalForm = {}
        this.specifyModalForm = {
          type:1,
          specName:'',
          serviceTime:'',
          overtimePay:'',
        }
        this.shopModalForm = {}
        this.secondList = []
        this.tailList = []
        this.uploadFileList = []
        this.shopMainFileList = []
        this.shopMainArrays = []
        this.tagsList = []
        this.serviceDet = [
          {text:''}
        ]
        this.tableData = []
        this.serviceContent =[
        {
          name:'',
          specDetail:'',
          serviceDet:[
            {text:''}
          ],
          stipulate:'',
          once: '',
          onceOriginalCost: '',
          twice: '',
          twiceOriginalCost: '',
          three: '',
          threeOriginalCost: '',
        }
      ]
        this.hmGoodsSiteSet = []
        this.hmGoodsSiteSetStr = ''
        this.imgTemplateId = ''
        this.imgTemplateList = []
        this.night = {
          nightService: 0,
          raisePriceType: '',
          raisePrice: '',
        }
        this.activityModalFormOne = {}
        this.activityModalFormTwo = {}
        this.activityList = []
        this.activityGoodsListOne = {}
        this.activityGoodsListTwo = {}
    },
    deactivated() {
      const menuTags =  JSON.parse(window.sessionStorage.getItem('menuTags'))
      const index = menuTags.findIndex(item => item.name === 'commodityEdit')
      if(index === -1) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.warp{
  margin-top: 20px;
  height: 100%;
  background: #FFFFFF;
  overflow: auto;
}

.header{
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  border-bottom: 15px solid #f8f8f8;
  position: sticky;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 99;
  .button{
    line-height: normal;
  }
}
.title{
  padding-bottom: 10px;
  font-weight: bold;
}
.information,
.specification,
.tableData,
.infoActivity{
  padding: 20px 40px;
  border-bottom: 15px solid #f8f8f8;

  .tagsList{
    .select_warp{
      display: flex;
      .button{
        height: 30px;
        line-height: 0;
      }
    }
    .tip{
      color: #999999;
      font-size: 12px;
    }
  }

}

.imgTemplateList {
  min-width: 320px;
  margin-left: 10px;
  .imgTemplateList_title {
    color: #606266;
    font-weight: normal;
  }
  .imgTemplate_warp{
    display: flex;
    flex-wrap: wrap;
    max-height: 250px;
    overflow: auto;
    .imgTemplate_item {
      margin: 0 10px 10px 0;
      padding: 5px;
      border: 2px solid #D9D9D9;
      cursor: pointer;
      position: relative;
    }
    .imgTemplate_active_icon {
      background: #1890FF;
      width: 15px;
      height: 15px;
      position: absolute;
      right: -1px;
      bottom: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .imgTemplate_item_active {
      border: 2px solid #1890FF;
    }

  }
}

.tableData{
  padding: 20px 40px;
  .item__input{
    width: 60px;
    /* 调整elementUI中样式 如果不需要调整请忽略 */
    .el-input__inner{
      height: 20px !important;
    }
    /* 调整elementUI中样式 如果不需要调整请忽略 */
    .el-input__suffix{
      i{
        font-size: 12px !important;
        line-height: 26px !important;
      }
    }
  }
  .item__txt{
    box-sizing: border-box;
    line-height: 30px;
    padding: 0 9px;
  }
}
.shop{
  padding: 20px 40px;
  width: 70%;
}

.serviceContent{
  padding: 20px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

::v-deep .td.el-table__cell {
  border: 1px solid #EEEEEE !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu__item{
  padding: 0 10px;
}
.el-select-dropdown__item{
  padding: 0 15px;
}
::v-deep .editor .w-e-text-placeholder{
  top: 6px !important;
}
</style>
